var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ori-resource" },
    [
      _vm.resource.uuid
        ? _c("ResourceCard", {
            attrs: {
              resource: _vm.resource,
              rank: _vm.rank,
              location: _vm.location,
              "has-overlay": _vm.needsReview,
              highlight: _vm.highlight,
              "modal-root-props": {
                needsReview: _vm.needsReview,
                templateId: _vm.resource.template_id,
                oriType: _vm.parentType,
                oriId: _vm.parentId
              },
              "modal-callback": _vm.modalCallback
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "overlay",
                  fn: function() {
                    return [
                      _vm.needsReview
                        ? _c("div", { staticClass: "ori-resource-review" }, [
                            _c(
                              "div",
                              { staticClass: "ori-resource-review-text" },
                              [
                                _c(
                                  "p",
                                  { staticClass: "ori-resource-review-top" },
                                  [_vm._v("Review content by")]
                                ),
                                _c(
                                  "p",
                                  { staticClass: "ori-resource-review-date" },
                                  [_vm._v(_vm._s(_vm.reviewTimeLeft))]
                                )
                              ]
                            )
                          ])
                        : _vm._e()
                    ]
                  },
                  proxy: true
                },
                {
                  key: "subTitle",
                  fn: function() {
                    return [
                      _vm.showTags
                        ? _c(
                            "div",
                            { staticClass: "ori-resource-review-sub" },
                            [
                              _c("ORITagEdit", {
                                attrs: {
                                  selected: _vm.resource.attributes,
                                  editable: _vm.canEdit,
                                  "tag-size": "xxs"
                                },
                                on: {
                                  input: function(att) {
                                    return _vm.$emit("editAttributes", att)
                                  }
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  },
                  proxy: true
                },
                {
                  key: "tags",
                  fn: function() {
                    return [
                      _vm.canEdit && _vm.statusText
                        ? _c("Tag", {
                            attrs: {
                              icon: _vm.statusIcon,
                              text: _vm.statusText,
                              "icon-left": true,
                              "filter-icon": false,
                              type: "white",
                              size: "xxs"
                            }
                          })
                        : _vm._e()
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              1094569758
            )
          })
        : _c(
            "ORIResourceGenerating",
            _vm._g({ attrs: { resource: _vm.resource } }, _vm.$listeners)
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }