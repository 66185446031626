<template>
  <div
    ref="oridetail"
    class="detail"
    :class="{ padded: !(isCreate || isEdit) }"
    :style="!(isCreate || isEdit) ? maxHeightStyle : {}"
  >
    <ORI
      v-if="!oriLoading && ori.name && !(isCreate || isEdit)"
      :key="oriKey"
      :ori="ori"
      :type="type"
      :key-mapping="
        isOffering
          ? {
              subofferings: 'offerings',
              inspirations: 'inspiration'
            }
          : {}
      "
      :title-mapping="
        isOffering
          ? {
              offerings: 'Subofferings'
            }
          : {}
      "
      :attribute-edit-loading="attributeEditLoading"
      @linkOffering="handleLinkOffering"
      @removeOffering="handleRemoveOffering"
      @linkReference="() => (showORILink = 'reference')"
      @removeReference="handleRemoveReference"
      @linkInspiration="() => (showORILink = 'inspiration')"
      @removeInspiration="handleRemoveInspiration"
      @linkResource="() => (showResourceModal = true)"
      @removeResource="handleRemoveResource"
      @offeringOrderChange="handleEditOfferingPosition"
      @resourceOrderChange="handleEditResourcePosition"
      @editAttributes="handleEditAttributes"
      @editResourceAttributes="handleEditResourceAttributes"
      @remove="showOriDeleteModal = true"
      @refresh="() => loadOri(true)"
      @edit="(o) => (ori = o)"
      @publish="(o) => (ori = o)"
      @publishResource="handlePublishResource"
    />
    <ORIActions
      v-if="isCreate || isEdit"
      :item="ori"
      :type="type"
      @create="(o) => (ori = o)"
      @edit="(o) => (ori = o)"
    />
    <ORIInit
      v-if="!!showORICreate"
      :visible="!!showORICreate"
      :type="showORICreate"
      :parent="
        showORICreate === 'offering' ? { ...ori, type: 'offering' } : undefined
      "
      :link="
        showORICreate === 'offering' ? undefined : { offering_ids: [ori.uuid] }
      "
      @close="() => (showORICreate = '')"
    />
    <div v-if="oriLoading" class="detail-loading">
      <b-loading :is-full-page="false" active />
    </div>
    <b-modal v-model="showResourceModal">
      <ORIUploadResourceModal
        v-if="useResourceUploadModal"
        :visible="showResourceModal"
        :props-call="uploadContentProps"
        :callback="uploadContentCallback"
        :max-size="5000"
        @close="showResourceModal = false"
        @done="finishContentUpload"
      />
      <AddResourceToORIModal
        v-else
        :ori="ori"
        :type="type"
        @close="showResourceModal = false"
        @add="handleAddContent"
      />
    </b-modal>
    <LinkORIModal
      v-if="!!showORILink"
      :item="ori"
      :type="type"
      :link-type="showORILink"
      :visible="!!showORILink"
      :selected="linkSelected"
      @close="(items) => handleCloseLinkingModal(showORILink, items)"
      @createNew="handleCreateNew"
    />
    <b-modal v-model="showOriDeleteModal">
      <RemoveORIModal
        :name="ori.name"
        :type="type"
        :uuid="ori.uuid"
        @close="showOriDeleteModal = false"
        @remove="handleRemoveOri"
      />
    </b-modal>
  </div>
</template>

<script>
import ORI from './ORI.vue'
import { mapActions, mapGetters } from 'vuex'
import AddResourceToORIModal from './AddResourceToORIModal.vue'
import RemoveORIModal from './RemoveORIModal.vue'
import LinkORIModal from './LinkORIModal.vue'
import ORIActions from './ORIActions.vue'
import ORIInit from './ORIInit.vue'
import ORIUploadResourceModal from './ORIUploadResourceModal.vue'
import { MaxHeightMixin } from '@/mixins/MaxHeightMixin'

export default {
  name: 'ORIDetail',
  components: {
    ORI,
    AddResourceToORIModal,
    RemoveORIModal,
    LinkORIModal,
    ORIActions,
    ORIInit,
    ORIUploadResourceModal
  },
  mixins: [MaxHeightMixin],
  props: {
    type: {
      type: String,
      default: 'offering',
      validator: (val) => ['offering', 'reference', 'inspiration'].includes(val)
    }
  },
  data: () => ({
    ori: {},
    oriLoading: false,
    showResourceModal: false,
    showOriDeleteModal: false,
    oriKey: 0,
    showORICreate: '',
    showORILink: '',
    attributeEditLoading: false
  }),
  computed: {
    ...mapGetters(['oriManagementEnabled']),
    typeName() {
      return {
        offering: 'offering',
        reference: 'case',
        inspiration: 'inspirational content'
      }[this.type]
    },
    isOffering() {
      return this.type === 'offering'
    },
    isCreate() {
      return this.$route.name === 'portfolio-create'
    },
    isEdit() {
      return this.$route.name === 'portfolio-edit'
    },
    currentOri() {
      return this.$route.params.ori_id
    },
    linkSelected() {
      if (!this.showORILink) return []
      const key = {
        reference: 'references',
        offering: this.isOffering ? 'subofferings' : 'offerings',
        inspiration: this.isOffering ? 'inspirations' : 'inspiration'
      }[this.showORILink]
      return this.ori[key] || []
    },
    useResourceUploadModal() {
      return this.oriManagementEnabled
    }
  },
  watch: {
    currentOri() {
      this.loadOri()
    }
  },
  async created() {
    this.loadOri()
  },
  methods: {
    ...mapActions([
      'getOfferingInfo',
      'getReferenceInfo',
      'getInspirationInfo',

      'editOfferingResourceLink',
      'editReferenceResourceLink',
      'editInspirationResourceLink',

      'editOfferingAttributes',
      'editReferenceAttributes',
      'editInspirationAttributes',

      'getOfferingResourceUploadProps',
      'getReferenceResourceUploadProps',
      'getInspirationResourceUploadProps',

      'processOfferingResourceUpload',
      'processReferenceResourceUpload',
      'processInspirationResourceUpload',

      'setOfferingPosition'
    ]),

    getMaxHeightElement() {
      return this.$refs.oridetail
    },

    async loadOri(silent = false) {
      try {
        if (!silent) this.oriLoading = true
        const infoFunction = {
          offering: this.getOfferingInfo,
          reference: this.getReferenceInfo,
          inspiration: this.getInspirationInfo
        }[this.type]
        this.ori = await infoFunction({
          workspace_id: this.$route.params.workspace_id,
          ori_id: this.$route.params.ori_id,
          act_as: this.$route.query.act_as
        })
      } catch (e) {
        this.$console.debug(`${this.typeName.capitalize()} retrieval failed`, e)
        this.$toast.error(e, `retrieving this ${this.typeName}`)
      } finally {
        this.oriLoading = false
      }
    },

    async handleAddContent(resources) {
      this.ori.resources = [
        ...this.ori.resources,
        ...resources.map((resource) => ({
          ...resource,
          attributes: []
        }))
      ]
      this.showResourceModal = false
      this.oriKey++
    },

    finishContentUpload() {
      this.showResourceModal = false
      this.loadOri(true)
    },

    async handleEditAttributes(attributes) {
      try {
        this.attributeEditLoading = true
        const attributeFunction = {
          offering: this.editOfferingAttributes,
          reference: this.editReferenceAttributes,
          inspiration: this.editInspirationAttributes
        }[this.type]
        const res = await attributeFunction({
          workspace_id: this.$route.params.workspace_id,
          ori_id: this.ori.uuid,
          attribute_value_ids: (attributes || []).reduce((acc, curr) => {
            acc.push(...curr.values.map((v) => v.uuid))
            return acc
          }, []),
          act_as: this.$route.query.act_as
        })
        this.ori.attributes = res.attributes
        this.ori.resources = res.resources
        this.$toast.success('Label successfully edited')
      } catch (e) {
        this.$console.debug('Label editing failed', e)
        this.$toast.error(e, 'editing the label')
      } finally {
        this.attributeEditLoading = false
      }
    },

    handleRemoveOri() {
      this.showOriDeleteModal = false
      this.$toast.success(`${this.typeName.capitalize()} successfully deleted`)
      this.$router.replace({
        name: 'portfolio',
        params: {
          workspace_id: this.$route.params.workspace_id,
          ori_type: this.$route.params.ori_type,
          ...(this.ori?.parent ? { ori_id: this.ori.parent.uuid } : {})
        }
      })
    },

    handleCloseLinkingModal(type, items) {
      this.ori[`${type}s`] = items
      this.showORILink = ''
      this.oriKey++
    },

    async handleRemoveReference(reference) {
      this.ori.references = this.ori.references.filter(
        (r) => r.uuid !== reference.uuid
      )
      this.oriKey++
    },

    async handleRemoveInspiration(inspiration) {
      const insp = `inspiration${this.type === 'offering' ? 's' : ''}`
      this.ori[insp] = this.ori[insp].filter((i) => i.uuid !== inspiration.uuid)
      this.oriKey++
    },

    async handleRemoveResource(resource) {
      this.ori.resources = this.ori.resources.filter(
        (r) => r.uuid !== resource.uuid
      )
      this.oriKey++
    },

    updateResources(resources) {
      this.ori.resources = resources
    },

    handlePublishResource(resource) {
      this.ori.resources = this.ori.resources.map((r) =>
        r.uuid === resource.uuid ? resource : r
      )
    },

    async handleRemoveOffering(offering) {
      this.ori.offerings = this.ori.offerings.filter(
        (o) => o.uuid !== offering.uuid
      )
    },

    handleLinkOffering() {
      this[`showORI${this.isOffering ? 'Create' : 'Link'}`] = 'offering'
    },

    async handleEditOfferingPosition({ from, to }) {
      try {
        const items = this.ori.subofferings
        const item = items[from]
        await this.setOfferingPosition({
          workspace_id: this.$route.params.workspace_id,
          ori_id: item.uuid,
          position: to + 1
        })
        this.ori.subofferings = [
          ...items
            .slice(0, to > from ? to + 1 : to)
            .filter((t) => t.uuid !== item.uuid),
          item,
          ...items
            .slice(to > from ? to + 1 : to)
            .filter((t) => t.uuid !== item.uuid)
        ]
      } catch (e) {
        this.$console.debug('Offering position change failed', e)
        this.$toast.error(e, 'changing your offering position')
      }
    },

    async handleEditResourcePosition({ from, to }) {
      try {
        const positionFunction = {
          offering: this.editOfferingResourceLink,
          reference: this.editReferenceResourceLink,
          inspiration: this.editInspirationResourceLink
        }[this.type]
        const items = this.ori.resources
        const item = items[from]
        await positionFunction({
          workspace_id: this.$route.params.workspace_id,
          ori_id: this.ori.uuid,
          resource_id: item.uuid,
          position: to + 1,
          act_as: this.$route.query.act_as
        })
        this.ori.resources = [
          ...items
            .slice(0, to > from ? to + 1 : to)
            .filter((t) => t.uuid !== item.uuid),
          item,
          ...items
            .slice(to > from ? to + 1 : to)
            .filter((t) => t.uuid !== item.uuid)
        ]
      } catch (e) {
        this.$console.debug('Content position change failed', e)
        this.$toast.error(e, 'changing the position of your content')
      }
    },

    handleEditResourceAttributes(attributes, resource) {
      try {
        const attributeFunction = {
          offering: this.editOfferingResourceLink,
          reference: this.editReferenceResourceLink,
          inspiration: this.editInspirationResourceLink
        }[this.type]
        attributeFunction({
          workspace_id: this.$route.params.workspace_id,
          ori_id: this.ori.uuid,
          resource_id: resource.uuid,
          attribute_value_ids: (attributes || []).reduce((acc, curr) => {
            acc.push(...curr.values.map((v) => v.uuid))
            return acc
          }, []),
          act_as: this.$route.query.act_as
        })
        const idx = this.ori.resources.findIndex(
          (r) => r.uuid === resource.uuid
        )
        this.ori.resources[idx].attributes = attributes
        this.$toast.success('Label successfully edited')
      } catch (e) {
        this.$console.debug('Label editing failed', e)
        this.$toast.error(e, 'editing the label')
      }
    },

    handleCreateNew(type) {
      this.showORICreate = type
      this.showORILink = ''
    },

    async uploadContentProps(file) {
      const uploadPropFunction = {
        offering: this.getOfferingResourceUploadProps,
        reference: this.getReferenceResourceUploadProps,
        inspiration: this.getInspirationResourceUploadProps
      }[this.type]
      return uploadPropFunction({
        workspace_id: this.$route.params.workspace_id,
        ori_id: this.ori.uuid,
        name: file.name,
        mimetype: file.type,
        content_length: file.size,
        act_as: this.$route.query.act_as
      })
    },

    async uploadContentCallback({ state, integration_file_id }) {
      const uploadCallback = {
        offering: this.processOfferingResourceUpload,
        reference: this.processReferenceResourceUpload,
        inspiration: this.processInspirationResourceUpload
      }[this.type]
      return uploadCallback({
        workspace_id: this.$route.params.workspace_id,
        ori_id: this.ori.uuid,
        state,
        integration_file_id,
        act_as: this.$route.query.act_as
      })
    }
  }
}
</script>

<style scoped lang="scss">
.detail {
  display: flex;
  flex-flow: column nowrap;
  gap: 1.5rem;
  padding: 2.5rem 2.5rem 0;
  overflow-y: auto;

  &.padded {
    padding: 2.5rem 2.5rem 4rem;
  }

  &-content {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
  }

  &-back {
    display: flex;
    flex-flow: row nowrap;
    gap: 1rem;
    align-items: center;

    &-text {
      cursor: pointer;
      transition: color 0.2s ease-in-out;

      &:hover {
        color: $primary;
      }
    }
  }

  &-loading {
    height: 10rem;
    position: relative;
  }
}
</style>
